/**
 * @file reducers/content.js
 * @author Hanif Permana <nifqi18@yahoo.com>
 * Date: 16.12.2016
 */

// import { ActionTypes } from '../constants'

/* eslint-env browser */
const content = (state = [], action) => {
  // console.log(action)
  //
  const { response, lastaction } = action


  // console.log(action)

  switch (action.type) {
    case 'REQUEST_RUN_RESET':
      return Object.assign({}, state, { status: 'reset', lastaction: {} })
    case 'REQUEST_RUN':
      return Object.assign({}, state, { status: 'idle' })
    case 'REQUEST_RUN_START':
      return Object.assign({}, state, { status: 'running', lastaction })
    case 'REQUEST_RUN_SUCCESS':
      return Object.assign({}, state, { status: 'success', response, lastaction })
    case 'REQUEST_RUN_FAILURE': {
      return Object.assign({}, state, { status: 'error', response, lastaction })
    }
    case 'REQUEST_RUN_FAKE':
      return Object.assign({}, state, { status: 'running', lastaction })
    case 'REQUEST_RUN_FAKE_SUCCESS':
      return Object.assign({}, state, { status: 'success', response })

    case 'REQUEST_RUN_FAKE_PATCH_LAST_ACTION':
      return Object.assign({}, state, { status: 'success', response })


    case 'REQUEST_RUN_FAKE_FAILURE': {
      /* di buat success di karenakan agar data sebelumnya juga muncul */
      return Object.assign({}, state, { status: 'error', response: state?.response, lastaction })
    }
    case 'REQUEST_RUN_RESET_POST_IDLE': {
      /* di buat success di karenakan agar data sebelumnya juga muncul */
      return Object.assign({}, state, { status: 'success', response: state?.response, lastaction })
    }
    default: {
      return state
    }
  }
}

const multiContent = (state = {}, action) => {
  if (typeof action?.reduxUid === 'undefined' && action?.type !== 'REQUEST_RUN_RESET_NON_ADMIN')
    return state

  if (action?.type === 'REQUEST_RUN_RESET_NON_ADMIN') {
    return {
      get_filter_administrator: state.get_filter_administrator,
    }
  }

  const { reduxUid } = action
  const newState = Object.assign({}, state)
  newState[reduxUid] = content(state[reduxUid], action)

  return newState
}

export default multiContent
